var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":_vm.formTitle}},[_c('b-card-body',[[_c('validation-observer',{ref:"staffForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm(_vm.submitForm)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"หน่วยงาน"},model:{value:(_vm.form.team),callback:function ($$v) {_vm.$set(_vm.form, "team", $$v)},expression:"form.team"}},[_c('validation-provider',{attrs:{"name":"หน่วยงาน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.teamChoices},model:{value:(_vm.form.team),callback:function ($$v) {_vm.$set(_vm.form, "team", $$v)},expression:"form.team"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name ชื่อ-สกุล","label-for":"staff-form-name"}},[_c('validation-provider',{attrs:{"name":"ชื่อ-สกุล","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"staff-form-name","placeholder":"ชื่อ-สกุล"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Mobile เบอร์โทรศัพท์","label-for":"staff-form-mobile"}},[_c('validation-provider',{attrs:{"name":"เบอร์โทรศัพท์","rules":"required|min:8|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"staff-form-mobile","type":"number","placeholder":"เบอร์โทรศัพท์","autocomplete":"off","state":errors.length > 0 ? false:null},model:{value:(_vm.form.mobileNumber),callback:function ($$v) {_vm.$set(_vm.form, "mobileNumber", $$v)},expression:"form.mobileNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Username ชื่อผู้ใช้สำหรับเข้าระบบ","label-for":"staff-form-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"staff-form-username","type":"text","placeholder":"Username","autocomplete":"off","disabled":!_vm.isCreateForm,"state":errors.length > 0 ? false:null},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.isCreateForm)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mt-2 mb-2",attrs:{"switch":"","inline":""},model:{value:(_vm.editChangePassword),callback:function ($$v) {_vm.editChangePassword=$$v},expression:"editChangePassword"}},[_vm._v(" เปลี่ยนรหัสผ่าน ")])],1):_vm._e(),(_vm.isCreateForm || _vm.editChangePassword)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"รหัสผ่าน","label-for":"staff-form-password"}},[_c('validation-provider',{attrs:{"name":"รหัสผ่าน","rules":"required|min:6","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"staff-form-password","type":_vm.passwordFieldType,"placeholder":"รหัสผ่าน","autocomplete":"off","state":errors.length > 0 ? false:null},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"RefreshCcwIcon"},on:{"click":_vm.randomPassword}})],1),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1942427921)})],1)],1):_vm._e(),(_vm.isCreateForm || _vm.editChangePassword)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ยืนยันรหัสผ่าน","label-for":"staff-form-confirm-password"}},[_c('validation-provider',{attrs:{"name":"ยืนยันรหัสผ่าน","rules":"required|min:6|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"staff-form-confirm-password","type":_vm.passwordFieldType,"placeholder":"ยืนยันรหัสผ่าน","autocomplete":"off","state":errors.length > 0 ? false:null},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1155084467)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" บันทึก ")])],1)],1)],1)],1)]],2)],1),(!_vm.isCreateForm)?_c('b-card',[_c('b-card-body',[_c('b-button',{attrs:{"variant":"danger","size":"md"},on:{"click":_vm.removeStaff}},[_vm._v(" ลบเจ้าหน้าที่ ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }