<template>
  <div>
    <b-card :title="formTitle">
      <b-card-body>
        <template>
          <validation-observer ref="staffForm">
            <b-form @submit.prevent="submitForm(submitForm)">
              <b-row>
                <!-- Team -->
                <b-col cols="12">
                  <b-form-group
                    v-model="form.team"
                    label="หน่วยงาน"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="หน่วยงาน"
                      rules="required"
                    >
                      <b-form-select
                        v-model="form.team"
                        :options="teamChoices"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- first name -->
                <b-col cols="12">
                  <b-form-group
                    label="Name ชื่อ-สกุล"
                    label-for="staff-form-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ชื่อ-สกุล"
                      rules="required"
                    >
                      <b-form-input
                        id="staff-form-name"
                        v-model="form.name"
                        placeholder="ชื่อ-สกุล"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- mobile -->
                <b-col cols="12">
                  <b-form-group
                    label="Mobile เบอร์โทรศัพท์"
                    label-for="staff-form-mobile"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="เบอร์โทรศัพท์"
                      rules="required|min:8|regex:^([0-9]+)$"
                    >
                      <b-form-input
                        id="staff-form-mobile"
                        v-model="form.mobileNumber"
                        type="number"
                        placeholder="เบอร์โทรศัพท์"
                        autocomplete="off"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Username -->
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Username ชื่อผู้ใช้สำหรับเข้าระบบ"
                    label-for="staff-form-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      rules="required|min:4"
                    >
                      <b-form-input
                        id="staff-form-username"
                        v-model="form.username"
                        type="text"
                        placeholder="Username"
                        autocomplete="off"
                        :disabled="!isCreateForm"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="!isCreateForm"
                  cols="12"
                >
                  <b-form-checkbox
                    v-model="editChangePassword"
                    class="mt-2 mb-2"
                    switch
                    inline
                  >
                    เปลี่ยนรหัสผ่าน
                  </b-form-checkbox>
                </b-col>

                <!-- Password -->
                <b-col
                  v-if="isCreateForm || editChangePassword"
                  cols="12"
                >
                  <b-form-group
                    label="รหัสผ่าน"
                    label-for="staff-form-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="รหัสผ่าน"
                      rules="required|min:6"
                      vid="password"
                    >
                      <b-input-group>
                        <b-form-input
                          id="staff-form-password"
                          v-model="form.password"
                          :type="passwordFieldType"
                          placeholder="รหัสผ่าน"
                          autocomplete="off"
                          :state="errors.length > 0 ? false:null"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            icon="RefreshCcwIcon"
                            class="cursor-pointer"
                            @click="randomPassword"
                          />
                        </b-input-group-append>
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Confirm Password -->
                <b-col
                  v-if="isCreateForm || editChangePassword"
                  cols="12"
                >
                  <b-form-group
                    label="ยืนยันรหัสผ่าน"
                    label-for="staff-form-confirm-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ยืนยันรหัสผ่าน"
                      rules="required|min:6|confirmed:password"
                    >
                      <b-form-input
                        id="staff-form-confirm-password"
                        v-model="form.confirmPassword"
                        :type="passwordFieldType"
                        placeholder="ยืนยันรหัสผ่าน"
                        autocomplete="off"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- reset and submit -->
                <b-col cols="12">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                  >
                    บันทึก
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </b-card-body>
    </b-card>
    <b-card v-if="!isCreateForm">
      <b-card-body>
        <b-button
          variant="danger"
          size="md"
          @click="removeStaff"
        >
          ลบเจ้าหน้าที่
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BForm, BFormGroup, BFormInput, BRow,
  BButton, BCardBody, BInputGroup, BInputGroupAppend, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed, password,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import errorHandleMixins from '@/mixins/error'
import axios from '@/libs/axios'

export default {
  components: {
    BCard,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, errorHandleMixins],
  data() {
    return {
      required,
      confirmed,
      password,
      editChangePassword: false,
      teamChoices: [],
      form: {
        _id: '',
        team: '',
        name: '',
        mobileNumber: '',
        username: '',
        password: '',
        confirmPassword: '',
      },
      initialForm: {
        _id: '',
        team: 'phc61',
        name: '',
        mobileNumber: '',
        username: '',
        password: '',
        confirmPassword: '',
      },
    }
  },
  computed: {
    isCreateForm() {
      // eslint-disable-next-line no-underscore-dangle
      return !this.form._id
    },
    formTitle() {
      // eslint-disable-next-line no-underscore-dangle
      if (this.form._id) {
        return 'แก้ไขข้อมูลเจ้าหน้าที่'
      }
      return 'เพิ่มเจ้าหน้าที่'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.path': function () {
      this.resetForm()
    },
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    randomPassword() {
      const length = 8
      const charset = 'abcdefghijklmnopqrstuvwxyz0123456789'
      let ranVal = ''
      for (let i = 0, n = charset.length; i < length; i += 1) {
        ranVal += charset.charAt(Math.floor(Math.random() * n))
      }
      this.form.password = ranVal
      this.form.confirmPassword = ranVal
      this.passwordFieldType = 'text'
    },
    async getTeamChoices() {
      const { data } = await axios.get('/staffs/create/team-choices')
      this.teamChoices = data
    },
    submitForm() {
      this.$refs.staffForm.validate().then(success => {
        if (!success) return

        let payload = {}
        if (this.isCreateForm) {
          payload = {
            team: this.form.team,
            name: this.form.name,
            mobileNumber: this.form.mobileNumber,
            username: this.form.username,
            password: this.form.password,
          }
          axios
            .post('/staffs/create', payload)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'เพิ่มเจ้าหน้าที่ใหม่เรียบร้อยแล้ว',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.resetForm()
            })
            .catch(e => {
              this.$errorHandler(e)
            })
        } else {
          payload = {
            team: this.form.team,
            name: this.form.name,
            mobileNumber: this.form.mobileNumber,
            password: this.editChangePassword ? this.form.password : null,
          }
          axios // eslint-disable-next-line no-underscore-dangle
            .put(`/staffs/detail/${this.form._id}`, payload)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'อัพเดตข้อมูลเรียบร้อยแล้ว',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(e => {
              this.$errorHandler(e)
            })
        }
      })
    },
    async refetchStaff() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        const response = await axios.get(`/staffs/detail/${this.form._id}`)

        const form = {
          // eslint-disable-next-line no-underscore-dangle
          _id: response.data._id,
          team: response.data.team || 'phc61',
          name: response.data.name,
          mobileNumber: response.data.mobileNumber,
          username: response.data.username,
        }
        this.form = { ...form }
      } catch (e) {
        this.$errorHandler(e)
      }
    },
    resetForm() {
      // eslint-disable-next-line no-underscore-dangle
      this.form._id = this.$route.params.staffId
      this.editChangePassword = false
      this.getTeamChoices()
      // eslint-disable-next-line no-underscore-dangle
      if (this.form._id) this.refetchStaff()
      else {
        this.form = { ...this.initialForm }
      }
      this.$refs.staffForm.reset()
    },
    async removeStaff() {
      const confirmResponse = await this.$swal({
        title: 'ยืนยันที่จะลบเจ้าหน้าที่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยันลบ',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-success ml-1',
        },
        buttonsStyling: false,
      })
      if (confirmResponse.value) {
        try {
          // eslint-disable-next-line no-underscore-dangle
          await axios.delete(`/staffs/detail/${this.form._id}`)
          this.$swal({
            icon: 'success',
            title: 'ลบพนักงานสำเร็จ',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          await this.$router.replace({ name: 'staff-list' })
        } catch (e) {
          this.$errorHandler(e)
        }
      }
    },
  },
}
</script>

<style>

</style>
